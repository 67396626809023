import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";

import { UitkSpacing } from "@egds/react-core/spacing";

import {
  ActivitySearchForm as SearchForm,
  ActivitySearchFormOptions,
  ActivitySearchFormInputs,
} from "@shared-ui/retail-search-tools-product";

import { ActivitySearchFormProps, ActivitySearchFormFlexModuleResult } from "./typings";

export const ActivitySearchForm = withStores(
  "flexModuleModelStore",
  "context",
  "page"
)(
  observer((props: ActivitySearchFormProps) => {
    const { templateComponent, flexModuleModelStore, page } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as ActivitySearchFormFlexModuleResult | null;

    /* istanbul ignore if */
    if (!model) {
      return null;
    }

    const searchFormProps: ActivitySearchFormOptions & { inputs: ActivitySearchFormInputs } = {
      redirectToSearchResultOnSubmit: true,
      additionalAdapterFields: { xPageId: page?.pageId ?? "" },
      inputs: { activitySearchCriteria: null },
    };

    return (
      <UitkSpacing
        margin={{
          small: {
            block: "three",
            inline: "three",
          },
          medium: {
            blockend: "three",
          },
        }}
      >
        <section id={id} data-testid="activity-search-form">
          <SearchForm {...searchFormProps} />
        </section>
      </UitkSpacing>
    );
  })
);

ActivitySearchForm.displayName = "ActivitySearchForm";

export default ActivitySearchForm;
